<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        :src="appLogoImage"
        alt="logo"
      />
      <h2 class="brand-text text-primary ml-1">
        {{ appName }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Survey Completed
        </h2>
        <h4 class="text-b-yellow">
          🎉Well done! You're a champion!🎉</h4>
        <p>Thanks so much for your contribution!</p>

        <p>We can't wait to analyse all the data. As soon as we're done, you'll be updated on our findings.</p>

        <p>From there, we'll let you know when the next questionnaire is due and what part of your workplace experience it will focus on.</p>

        <p>If you are experiencing problems with your mental wellbeing, know you are not alone! Please don't wait to reach out to any of the resources below. They're here to help!</p>

        <h4>Mind</h4>
        <p><a
          href="https://www.mind.org.uk"
          target="_blank"
        >www.mind.org.uk</a> <br>
          0300 123 3393<br>
          A charity providing information on mental health, sources of help and advocacy</p>

        <h4>Samaritans</h4>
        <p><a
          href="https://www.samaritans.org"
          target="_blank"
        >www.samaritans.org</a> <br>
          116 123<br>
          A 24-hour telephone helpline for people struggling to cope.</p>

        <h4>NHS Choices</h4>
        <p><a
          href="https://www.nhs.uk/conditions/"
          target="_blank"
        >www.nhs.uk/conditions</a><br>
          Provides information on treatments for various medical conditions, including mental health conditions, available through the NHS.</p>

        <p>If you are feeling suicidal, there are people you can talk to who want to help</p>
        <ul>
          <li> Call the Samaritans 24-hour support service on 116 123</li>
          <li> Go to your nearest accident and emergency (A&mp;E) department and tell the staff how you are feeling</li>
          <li> Contact NHS 111</li>
          <li> Make an urgent appointment to see your GP</li>
        </ul>
        <p>Again, thanks so much for participating and see you next time :)</p>
        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Thank you page"
          class="img-70"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg } from 'bootstrap-vue'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
import axios from '@axios'

export default {
  components: {
    BLink,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  created() {
    this.setSurveyCompleted()
  },
  methods: {
    setSurveyCompleted() {
      if (this.$route.query.u) {
        axios
          .post('/survey/completed', { userId: this.$route.query.u, surveyId: this.$route.query.s })
          .then(response => {
            console.log(response)
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
